<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          v-if="customFormEdit || canEdit"
        >
          <b-row align-v="end">
            <b-col cols="6">
              <div class="form-group">
                <validation-provider
                  name="antecedentes"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-if="!fields.custom"
                    id="antecedente-oftalmo"
                    v-model="fields.antecedente"
                    :options="antecedentes"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :allowEmpty="false"
                    :showNoResults="true"
                    placeholder="Pesquisar antecedente"
                    class="search-mode with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    :disabled="!canEdit"
                  >
                    <template slot="caret">
                      <div class="search">
                        <Search />
                      </div>
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(true, props.search)">
                        <div class="multiselect__option custom-item">
                          <Plus class="icon" />
                          Adicionar antecedente "{{ props.search }}"
                        </div>
                      </li>
                    </template>

                    <template slot="noOptions"> Nenhuma opção </template>
                  </multiselect>
                  <div class="custom-input" v-else>
                    <b-button variant="link" @click="setCustom(false)"
                      >Selecionar um item da lista</b-button
                    >
                    <input
                      autocomplete="off"
                      v-model="fields.antecedente"
                      :readonly="!canEdit"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    />
                  </div>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group">
                <validation-provider
                  name="situação"
                  :rules="{ required: false }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    id="situacao"
                    v-model="fields.situacao"
                    :options="[
                      'Em tratamento',
                      'Resolvido',
                      'Descompensado',
                      'Sem tratamento'
                    ]"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar situação"
                    class="with-border"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    :disabled="!canEdit"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>

                    <template slot="noOptions"> Nenhuma opção </template>

                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="2">
              <b-button
                block
                variant="primary"
                class="h-38 mt-27 button"
                :disabled="invalid"
                @click="saveItem"
              >
                {{ !editingIndex ? 'Adicionar' : 'Atualizar' }}
              </b-button
              >
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col>
            <table class="table-eyecare">
              <thead>
                <tr>
                  <th width="51%">Nome</th>
                  <th>Situação</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) of form.value" :key="index">
                  <td
                    v-b-tooltip.hover :title="value?.antecedente?.length >= 110 ? value.antecedente : ''"
                  >
                    {{ value?.antecedente?.length >= 110 ? value.antecedente.slice(0, 110)+'...' : value.antecedente }}
                  </td>
                  <td>{{ value.situacao }}</td>
                  <td class="text-right">
                    <div class="more-with-menu">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button
                          variant="link"
                          @click="editItem(value, index)"
                          :disabled="!canEdit"
                        >
                          Editar
                        </b-button>
                        <b-button
                          variant="link remove mb-0"
                          @click="removeItem(index)"
                          :disabled="!canEdit"
                        >
                          Remover
                        </b-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: {
    FormWrapper,
    Search,
    Plus,
    MoreVertical,
    ChevronDown
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      customFormEdit: state => state.attendance.customFormEdit,
      form: state => state.attendance.form.antecedentesOftalmologicos,
    })
  },
  inject: ['antecedentes'],
  data() {
    return {
      editingIndex: null,
      fields: {
        custom: false,
        antecedente: null,
        situacao: null
      },
    }
  },
  methods: {
    ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/antecedentesOftalmologicos', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.antecedente = value ? searchTerm : null
    },
    saveItem() {
      if (this.editingIndex === null && this.form?.value?.some(v => v.antecedente?.toLowerCase() === this.fields?.antecedente?.toLowerCase())) {
        this.$toast.warning('Antecedente oftalmológico já adicionado')
        return
      }

      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      this.fields.ultimaAtualizacao = this.moment().format('DD/MM/YYYY')
      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)

      this.fields = {
        custom: false,
        antecedente: null,
        situacao: null
      }

      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
    }
  }
}
</script>
<style lang="scss" scoped>
.button {
  height: 38px;
  margin-bottom: 10px;
}

.icon{
  height: 24px;
  width: 24px;
}
</style>
